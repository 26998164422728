const httpRequest = method => async (url, body, responseType = 'json') => {
	let headers = {
		'X-Requested-With': 'XMLHttpRequest',
	};

	if (body) {
		const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

		headers = {
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest',
			'CSRF-Token': token,
		};
	}

	const response = await fetch(url, {
		method,
		body,
		headers,
	});

	if (response.status === 401) {
		const { referer } = await response.json();

		window.location = referer || '/';
	}

	if (!response.ok) {
		throw Error(response.statusText);
	}

	return response[responseType]();
};

const get = httpRequest('GET');

const patch = httpRequest('PATCH');

const post = httpRequest('POST');

const put = httpRequest('PUT');

export {
	get,
	patch,
	post,
	put,
};
