const TEMPLATE = `
	<div class="load-mask">
		<span class="sr-only">Loading</span>
	</div>
`;

const DELAY = 100;

class LoadMask {
	constructor(el) {
		this.el = el;

		this.isLoading = false;
	}

	render() {
		if (!this.el.style.position) {
			this.el.style.position = 'relative';
		}

		const node = document.createElement('div');

		node.innerHTML = TEMPLATE;

		const loader = node.firstElementChild;

		this.el.appendChild(loader);

		this.mask = loader;
	}

	show() {
		this.isLoading = true;

		setTimeout(() => {
			if (this.isLoading) {
				if (!this.mask) {
					this.render();
				}

				this.mask.hidden = false;
			}
		}, DELAY);
	}

	hide() {
		if (this.mask) {
			this.mask.hidden = true;
		}

		this.isLoading = false;
	}

	setEl(el) {
		if (typeof el === 'string') {
			el = this.el.querySelector(el);
		}

		this.el = el;
	}
}

export default LoadMask;
