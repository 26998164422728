import Base from './base';
import FieldValidator from './field-validator';

class Form extends Base {
	constructor(el) {
		super(el);

		this.submitButton = this.query('.js-submit');

		this.on('submit', e => this.onSubmit(e));

		this.fieldValidator = new FieldValidator(this.el);
	}

	start() {
		return this;
	}

	onSubmit(e) {
		e.preventDefault();

		const { target } = e;
		const isValid = target.checkValidity();

		if (!isValid) {
			this.validate();

			return;
		}

		this.toggleSubmitterState();

		this.submitForm(e);
	}

	getFieldValue(value, key) {
		const field = this.query(`[data-field="${key}"`, this.body);

		if (value && field && 'useUnformattedValue' in field.dataset) {
			const { validation } = field.dataset;
			const unformatted = this.fieldValidator.unformat(value, validation).toString();

			return unformatted;
		}

		return value;
	}

	submitForm(e) {
		e.target.submit();
	}

	validate() {
		this.fieldValidator.validateFields(this.el);

		this.focusField('.is-invalid');

		this.eventbus.emit('notification:show', {
			text: 'Please fill in all required fields.',
			type: 'warning',
		});
	}

	toggleSubmitterState() {
		this.submitButton.disabled = !this.submitButton.disabled;

		this.submitButton.classList.toggle('is-loading');
	}

	focusField(field) {
		const el = this.query(field);

		if (!el) {
			return;
		}

		el.focus();
	}
}

export default Form;
