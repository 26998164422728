import eventbus from '../utils/eventbus';

const TEMPLATE = `
	<div class="modal js-modal is-hidden" role="dialog" aria-modal="true">
		<div class="modal__wrapper">
			<button class="modal__close button button--icon-only button--light js-close">
				<svg viewBox="0 0 24 24" class="icon icon--l">
					<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/icons/sprite.svg#icon-close"></use>
				</svg>
			</button>
			<h1 class="modal__header js-header"></h1>
			<div class="modal__body js-body"></div>
		</div>
	</div>
`;

class Modal {
	constructor(el, options = {}) {
		this.el = el;

		this.modal = null;
		this.header = null;
		this.body = null;
		this.isOpen = false;
		this.options = options;

		this.render();
	}

	render() {
		const node = document.createElement('div');

		node.innerHTML = TEMPLATE;

		const modal = node.firstElementChild;

		if (this.options.fluid) {
			modal.classList.add('modal--fluid');
		}

		this.el.parentNode.insertBefore(modal, this.el);

		this.modal = modal;

		this.afterRender();
	}

	afterRender() {
		this.body = this.modal.querySelector('.js-body');
		this.header = this.modal.querySelector('.js-header');

		this.modal.addEventListener('click', e => this.onClick(e));

		eventbus.on('modal:close', () => this.close());
	}

	setBody(el) {
		this.body.appendChild(el);
	}

	clearBody() {
		this.body.innerHTML = '';
	}

	setTitle(title) {
		this.header.innerText = title;
	}

	onClick(e) {
		if (e.target.classList.contains('js-close')) {
			this.close();
		}
	}

	setClasses() {
		this.modal.classList.toggle('is-visible');

		this.modal.classList.toggle('is-hidden');
	}

	open(el, title) {
		if (this.isOpen) {
			return;
		}

		this.isOpen = true;

		this.setBody(el);

		this.setTitle(title);

		this.setClasses();
	}

	close() {
		this.isOpen = false;

		this.clearBody();

		this.setClasses();
	}
}

export default Modal;
