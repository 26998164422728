import Base from './base';
import Modal from './modal';
import Notification from './notification';
import FromRequestDemo from './form-request-demo';

const TEMPLATE_REQUEST_DEMO = `
	<form action="/subscribe" method="post" class="js-form" novalidate>
		<p>
			You will receive a link by email to our demo. Feel free to contact us any time.
			Call <a href="tel:+31 10 714 44 58">+31 10 714 44 58</a> or email <a href="mailto:info@producttwo.com">info@producttwo.com</a>.
		</p>
		<div class="field js-field">
			<label class="field__label" for="mce-EMAIL">Email address*</label>
			<input class="field__control js-control" type="email" name="EMAIL" id="mce-EMAIL" required>
			<div class="field__error js-error" hidden></div>
		</div>
		<div class="field">
			<label class="field__label" for="mce-COMPANY">Company/Organisation</label>
			<input class="field__control" type="text" name="COMPANY" id="mce-COMPANY">
		</div>
		<div class="field">
			<label class="field__label" for="mce-PHONE">Phone number</label>
			<input class="field__control" type="text" name="PHONE" id="mce-PHONE">
		</div>
		<div class="sr-only" aria-hidden="true">
			<input type="text" name="b_277b5e39fc0142e7dc9b95c78_3a9810833e" tabindex="-1"></div>
		<div class="field">
			<button type="submit" name="subscribe" id="mc-embedded-subscribe" class="button button--primary button--large button--fluid js-submit">
				Submit
			</button>
		</div>
		<p>
			<small class="text-muted">
				Your information will only be used to get in contact and <span class="font-bold">will not be shared</span> with 3rd parties.</a>
			</small>
		</p>
		<p>
			<small class="text-muted">
				We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing.<br> <a href="https://mailchimp.com/legal/" target="_blank">Learn more about Mailchimp's privacy practices here.</a>
			</small>
		</p>
		<div class="font-bold js-feedback"></div>
	</form>
`;

class Landing extends Base {
	constructor(el) {
		super(el);

		this.modal = new Modal(this.el);
		this.notification = new Notification(this.el).start();

		this.on('click', e => this.onClick(e));
	}

	start() {
		return this;
	}

	onClick(e) {
		if (e.target.classList.contains('js-demo')) {
			e.preventDefault();

			this.openModal();
		}
	}

	openModal() {
		const node = document.createElement('div');

		node.innerHTML = TEMPLATE_REQUEST_DEMO;
		const el = node.firstElementChild;

		this.modal.open(el, 'Request your free demo');

		this.schema = new FromRequestDemo(el);
	}
}

export default Landing;
