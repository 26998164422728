import Form from './form';

import { post } from '../utils/http';

class FormRequestDemo extends Form {
	constructor(el) {
		super(el);

		this.feedback = this.query('.js-feedback');

		this.focusField('.js-control');
	}

	getFormData() {
		const formData = new FormData(this.el);
		const data = {};

		for (const [key, value] of formData.entries()) {
			data[key] = value;
		}

		return JSON.stringify(data);
	}

	async submitForm(e) {
		const body = this.getFormData();

		try {
			const data = await post(e.target.action, body);

			this.toggleSubmitterState();

			this.showFeedback(data);
		} catch (err) {
			this.toggleSubmitterState();

			this.eventbus.emit('notification:show', {
				text: 'Could not send request. Please try again.',
				type: 'error',
			});
		}
	}

	showFeedback({ msg, result }) {
		this.feedback.classList.remove('text-info', 'text-error');

		if (result === 'error') {
			this.feedback.classList.add('text-error');
			this.feedback.innerHTML = msg;

			return;
		}

		this.feedback.classList.add('text-info');

		this.feedback.innerHTML = msg;

		this.submitButton.disabled = true;
	}
}

export default FormRequestDemo;
