import eventbus from '../utils/eventbus';

import LoadMask from './load-mask';

class Base {
	constructor(el) {
		this.el = el;

		this.eventbus = eventbus;

		this.loadMask = new LoadMask(this.el);
	}

	query(selector, root = this.el) {
		return root.querySelector(selector);
	}

	queryAll(selector, root = this.el) {
		return root.querySelectorAll(selector);
	}

	on(type, listener, root = this.el) {
		if (typeof root === 'string') {
			root = this.query(root);
		}

		if (!root) {
			return;
		}

		root.addEventListener(type, listener);
	}
}

export default Base;
