import eventbus from '../utils/eventbus';

const TEMPLATE = '<div class="notification js-notification"></div>';

class Notification {
	constructor(el) {
		this.el = el;

		eventbus.on('notification:show', body => this.onShow(body));
	}

	start() {
		this.render();
	}

	render() {
		this.el.insertAdjacentHTML('afterbegin', TEMPLATE);

		this.notification = this.el.querySelector('.js-notification');
	}

	onShow(body) {
		this.show(body);

		setTimeout(() => this.hide(body.type), 5000);
	}

	show({ text, type = 'info' }) {
		this.notification.innerText = text;

		this.toggleClassNames('add', type);
	}

	hide(type = 'info') {
		this.notification.innerText = '';

		this.toggleClassNames('remove', type);
	}

	toggleClassNames(method, type) {
		this.notification.classList[method](`notification--${type}`, 'is-visible');
	}
}

export default Notification;
