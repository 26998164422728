import Base from './base';

const ACTIVE_CLASS = 'is-active';

class Accordion extends Base {
	constructor(el) {
		super(el);

		this.on('click', e => this.onClick(e));

		this.image = this.query('.js-accordion-image');
	}

	start() {
		const el = this.query('.js-accordion-item');

		if (el) {
			this.toggle(el);
		}
	}

	onClick(e) {
		const accordionLink = e.target.closest('.js-accordion-link');

		if (accordionLink) {
			e.preventDefault();

			this.onToggle(accordionLink);
		}
	}

	onToggle(el) {
		const target = el.closest('.js-accordion-item');

		this.removeActiveState();

		this.toggle(target);

		if (this.image) {
			this.setImage(target);
		}
	}

	removeActiveState() {
		const el = this.query(`.${ACTIVE_CLASS}`);

		if (el) {
			el.classList.remove(ACTIVE_CLASS);
		}
	}

	setImage(el) {
		const { image } = el.dataset;

		if (image) {
			this.image.src = `/images/accordion/${image}.png`;
		}
	}

	toggle(el) {
		el.classList.toggle(ACTIVE_CLASS);
	}
}

export default Accordion;
