// Import any polyfills not in core-js here before main (e.g. whatwg-fetch, dom4).
import 'whatwg-fetch';

import checkBrowserSupport from './check-browser-support.mjs';
import start from './start.mjs';

checkBrowserSupport();

start();

